.footer{
    background: #F7F7F7;
} 

.footer-top .footer-content{
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding: 48px 0;
}

.footer-content .row {
    margin: 0 -18px;
}

.footer-content .row>* {
    padding: 0 18px;
}

.footer-logo-part .footer-logo {
    width: 100%;
    max-width: 250px;
}

.footer-logo-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer-logo-part .footer-info {
    margin-top: 108px;
    width: 100%;
    max-width: 380px;
    padding: 16px;
    border-radius: 10px;
    background: #EFEFEF;
}

.footer-info .footer-info-title * {
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
}

.footer-info .common-detail {
    font-family: 'segoeuisemibold';
    color: #969696;
    font-size: 16px;
    line-height: 130%;
    margin-top: 8px;
}

.footer-link-block{
    height: 100%;
}

.footer-link-part .footer-link-title * {
    color: #484848;
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
}

.footer-link-part .footer-link-list {
    margin-top: 24px;
}

.footer-link-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-link-list ul li {
    font-family: 'segoeuisemibold';
    list-style: none;
    margin-bottom: 16px;
    line-height: 130%;
}

.footer-link-list ul li a {
    cursor: pointer;
}

.footer-link-list ul li:last-child{
    margin-bottom: 0;
}

.footer-link-list ul li a:hover,
.footer-link-list ul li a.active {
    color: #E02C22 !important;
}

.footer-bottom {
    padding: 24px 0;
}

.footer-copyright {
    text-align: center;
    color: #969696;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}

/* responsive start */
@media (min-width: 768px) and (max-width: 991px){
    .footer-content .row{margin: 0 -21px;}
    .footer-content .row>*{ padding: 0 21px; }
}

@media (max-width: 767px){
    .footer-content .row{margin: 0 -5px;}
    .footer-content .row>*{ padding: 0 5px; }
    .footer-logo-part .footer-info{margin-top: 32px; max-width: 342px;}
    .footer-link-part{margin-top: 32px;}
    .footer-link-block .row > *:last-child .footer-link-part{margin-top: 48px;}
}
/* responsive end */