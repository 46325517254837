.header {
    padding: 14px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.header .navbar {
    padding: 0;
}

.header .navbar-brand {
    max-width: 200px;
    width: 100%;
    padding: 0;
    margin: 0;
}

.header .navbar-nav .nav-link {
    font-size: 16px;
    line-height: 160%;
    color: #484848;
    padding: 0;
    margin: 0 24px;
    cursor: pointer;
}

.header .navbar-nav .nav-link:hover,
.header .navbar-nav .nav-link.active {
    color: #E02C22;
}

.header .navbar-nav .nav-link:first-child {
    margin-left: 0;
}

.header .navbar-nav .nav-link:last-child {
    margin-right: 0;
}


@media (max-width:991px) {
    .header { padding: 24px 0; }
    .header .navbar-toggler {border: none; padding: 0; box-shadow: none; height: 16px; z-index: 9;}
    .header .navbar-toggler-icon { background-image: none; width: 21px; background-color: #727272; transform: rotate(45deg); display: block; height: 2px; position: relative; transition: 0.5s; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; -webkit-transform: rotate(0deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); }
    .header .navbar-toggler:not(.collapsed) .navbar-toggler-icon { transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); }
    .header .navbar-toggler-icon::before, .header .navbar-toggler-icon::after { content: ""; width: 21px; background-color: #727272; display: block; height: 2px; position: relative;}
    .header .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before { top: 0; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); }
    .header .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after { bottom: -5px; opacity: 0; }
    .header .navbar-toggler-icon::after {bottom: -5px; opacity: 1;}
    .header .navbar-brand { max-width: 175px; position: relative; z-index: 9; }
    .header .navbar-toggler-icon::before { top: -7px; transform: rotate(0deg); -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); }
    .header .navbar-collapse { position: fixed; background-color: #fff; right: -100%; top: 0; bottom: 0; max-width: 100%; width: 100%; height: 100%; overflow: auto; padding: 200px 40px; transition: 0.5s; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; }
    .header .navbar-collapse.show {right: 0; height: auto;}
    .header .navbar-nav .nav-link {display: inline-block; width: auto; margin: 0 0 60px;}
    .header .navbar-nav {align-items: center;}
    .header-btn {text-align: center;}
    .header .navbar-collapse::after { content: ""; position: fixed; background-color: #fff; right: -100%; top: 0; bottom: 0; max-width: 100%; width: 100%; height: 78px; transition: 0.5s; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; }
    .header .navbar-collapse.show::after {right: 0;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .header .container {max-width: 100%; padding: 0 24px;}
}