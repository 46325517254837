.banner-left span {
    font-family: 'SegoeUIBold';
    font-size: 16px;
    line-height: 130%;
    color: #EFEFEF;
    display: block;
    padding: 0 0 16px;
}
 
.banner-right .common-detail {
    color: #EFEFEF;
    margin: 0 0 24px;
}

.banner-top {
    padding: 0 0 64px;
}

.banner-wrp {
    padding: 325px 0 48px;
    position: relative;
    z-index: 9;
    margin: 83px 0 0;
}

.banner-wrp::after {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.banner-slider .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    opacity: 0.4;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.banner-slider .slick-slide > div {
    display: flex;
}

.banner-slider .slick-slide:hover {
    opacity: 1;
}

.banner-slider .slick-slider {
    margin: 0 -62px;
    opacity: 0;
}

.slick-slider.slick-initialized {
    opacity: 1;
}

.banner-slider .slick-track {
    display: flex;
    align-items: center;
}

/* Responsive Start */ 
@media (min-width: 1300px) and (max-width: 1399px) {
    .banner-slider .slick-slide { padding: 0 30px; text-align: center; }
    .banner-slider .slick-slider {margin: 0 -30px;}
    .banner-slider .slick-slide .banner-item img {margin: 0 auto;}
    .banner-slider .slick-slide:first-child .banner-item img {margin-left: 0;}
    .banner-slider .slick-slide:last-child .banner-item img {margin-right: 0;}
    .banner-wrp { padding: 250px 0 48px; }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .banner-slider .slick-slide { padding: 0 30px; text-align: center; }
    .banner-slider .slick-slider {margin: 0 -30px;}
    .banner-slider .slick-slide .banner-item img {margin: 0 auto;}
    .banner-slider .slick-slide:first-child .banner-item img {margin-left: 0;}
    .banner-slider .slick-slide:last-child .banner-item img {margin-right: 0;}
    .banner-wrp { padding: 250px 0 48px; }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .banner-slider .slick-slide { padding: 0 18px; text-align: center; }
    .banner-slider .slick-slider {margin: 0 -18px;}
    .banner-slider .slick-slide .banner-item img {margin: 0 auto;}
    .banner-slider .slick-slide:first-child .banner-item img {margin-left: 0;}
    .banner-slider .slick-slide:last-child .banner-item img {margin-right: 0;}
    .banner-wrp { padding: 200px 0 48px; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .banner-slider .slick-slide { padding: 0 18px; text-align: center; }
    .banner-slider .slick-slider {margin: 0 -18px;}
    .banner-slider .slick-slide .banner-item img {margin: 0 auto;}
    .banner-slider .slick-slide:first-child .banner-item img {margin-left: 0;}
    .banner-slider .slick-slide:last-child .banner-item img {margin-right: 0;}
    .banner-wrp { padding: 150px 0 48px; }
    .banner-left {padding: 0 0 30px;}
    .banner-top { padding: 0 0 50px; }
}

@media (max-width: 767px) {
    .banner-slider .slick-slide { padding: 0 15px; text-align: center; }
    .banner-slider .slick-slider {margin: 0 -15px;}
    .banner-slider .slick-slide .banner-item img {margin: 0 auto;}
    .banner-slider .slick-slide:first-child .banner-item img {margin-left: 0;}
    .banner-slider .slick-slide:last-child .banner-item img {margin-right: 0;}
    .banner-wrp { padding: 64px 0; margin: 78px 0 0; }
    .banner-left {padding: 0 0 138px;}
}
/* Responsive End */