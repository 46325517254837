.speedscan-row {
    padding: 80px 0;
}

.speedscan-img {
    text-align: center;
}

.speedscan-img img {
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.count-block {
    max-width: 183px;
    width: 100%;
    background-color: #007cc7;
    padding: 14px 20px;
    margin: 0 0 20px;
}

.count-block .block-title {
    margin: 0 0 4px;
}

.count-block p {
    /* font-family: 'segoeuisemibold'; */
    font-family: 'SegoeUISemibold';
    color: #fff;
    margin: 0;
}

.speedscan-wrp .speedscan-row:nth-child(odd) .count-block {
    border-radius: 16px 16px 4px 16px;
    -webkit-border-radius: 16px 16px 4px 16px;
    -moz-border-radius: 16px 16px 4px 16px;
    -ms-border-radius: 16px 16px 4px 16px;
    -o-border-radius: 16px 16px 4px 16px;
}

.speedscan-wrp .speedscan-row:nth-child(even) .count-block {
    border-radius: 16px 16px 16px 4px;
    -webkit-border-radius: 16px 16px 16px 4px;
    -moz-border-radius: 16px 16px 16px 4px;
    -ms-border-radius: 16px 16px 16px 4px;
    -o-border-radius: 16px 16px 16px 4px;
}

.speedscan-count .count-block:last-child {
    margin-bottom: 0;
}

.speedscan-left {
    position: relative;
}

.speedscan-count {
    position: absolute;
    bottom: 32px;
}

.speedscan-wrp .speedscan-row:nth-child(even) {
    background-color: #FAFAFA;
}

.speedscan-wrp .speedscan-row:nth-child(even) .speedscan-count {
    right: 0;
}

.speedscan-wrp .speedscan-row:nth-child(even) .row {
    flex-direction: row-reverse;
}

.speedscan-right .section-title {
    margin: 0 0 16px;
}

.speedscan-right .common-detail {
    color: #484848;
    margin: 0 0 32px;
}

.speedscan-block {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 16px;
    height: calc(100% - 16px);
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.speedscan-content .row {
    margin: 0 -8px;
}

.speedscan-content .row>* {
    padding: 0 8px;
}

.speedscan-content {
    padding: 0 0 16px;
}

.speedscan-btn .btn-gradient {
    margin: 0 32px 0 0;
}

.coming-soon {
    padding: 5px 16px  1px 16px;
    max-width: 127px;
    width: 100%;
    margin: 0 0 8px;
    background-color: rgba(237, 119, 27, 0.2);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.coming-soon span {
    font-family: 'LeagueSpartanBold';
    background: linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
}

.speedscan-block .detail p {
    font-size: 14px;
    line-height: 150%;
    color: #727272;
    margin: 0;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .speedscan-row { padding: 60px 0; }
}

@media (min-width: 768px) and (max-width: 992px) {
    .speedscan-row { padding: 60px 0; }
    .speedscan-wrp .speedscan-row:nth-child(odd) .speedscan-section > .row, .speedscan-wrp .speedscan-row:nth-child(even) .speedscan-section > .row  {flex-direction: column-reverse;}
    .speedscan-right {padding: 0 0 40px;}
    .speedscan-wrp .speedscan-row:nth-child(even) .speedscan-count { right: auto; }
}

@media (max-width: 767px) {
    .speedscan-row { padding: 48px 0; }
    .speedscan-wrp .speedscan-row:nth-child(odd) .speedscan-section > .row, .speedscan-wrp .speedscan-row:nth-child(even) .speedscan-section > .row  {flex-direction: column-reverse;}
    .speedscan-right {padding: 0 0 40px;}
    .speedscan-wrp .speedscan-row:nth-child(odd) .speedscan-img {margin: 0 0 0 63px;}
    .speedscan-img img { border-radius: 11px; -webkit-border-radius: 11px; -moz-border-radius: 11px; -ms-border-radius: 11px; -o-border-radius: 11px; }
    .count-block .block-title { margin: 0 0 2px; }
    .count-block p {font-size: 12px; line-height: 16px;}
    .count-block {padding: 9px 13px; margin: 0 0 13px;}
    .speedscan-count {bottom: 22px;}
    .speedscan-wrp .speedscan-row:nth-child(even) .speedscan-img {margin: 0 0 0 63px;}
    .speedscan-wrp .speedscan-row:nth-child(even) .speedscan-count { right: auto; }
}

@media (max-width: 575px) {
    .speedscan-btn {display: flex; flex-direction: column; align-items: center;}
    .speedscan-btn .btn-gradient { margin: 0 0 32px 0; }
}
/* Responsive End */