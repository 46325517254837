.reviews-wrp {
    padding: 96px 0;
}

.reviews-main .section-title {
    text-align: center;
    margin: 0 0 48px;
}

.reviews-detail-slider {
    max-width: 584px;
    width: 100%;
    margin: 48px auto 0;
    text-align: center;
}

.reviews-detail-item .small-title {
    margin: 0 0 16px;
    color: #484848;
}

.reviews-detail-item .detail p {
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.reviews-detail-bottom {
    padding: 32px 0 0;
}

.reviews-detail-img {
    width: 120px;
    height: 120px;
    margin: 0 auto 8px;
}

.reviews-detail-img img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.reviews-detail-content h5 {
    font-family: 'SegoeUIBold';
    color: #484848;
    margin: 0 0 4px;
}

.reviews-detail-content p {
    font-size: 14px;
    margin: 0;
}

.reviews-logo-item img {
    opacity: 0.2;
    margin: 0 auto;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.reviews-logo-slider .slick-current .reviews-logo-item img {
    opacity: 1;
}

.slick-slide {
    box-shadow: none !important;
    outline: none !important;
}

.reviews-logo-slider .slick-arrow {
    display: flex !important;
    position: absolute;
    top: 50%;
    cursor: pointer;
}

.reviews-logo-slider .slick-prev {
    left: 50%;
    transform: translate(-132px, -50%);
    -webkit-transform: translate(-132px, -50%);
    -moz-transform: translate(-132px, -50%);
    -ms-transform: translate(-132px, -50%);
    -o-transform: translate(-132px, -50%);
}

.reviews-logo-slider .slick-next {
    left: 50%;
    transform:translate(120px, -50%);
    -webkit-transform:translate(120px, -50%);
    -moz-transform:translate(120px, -50%);
    -ms-transform:translate(120px, -50%);
    -o-transform:translate(120px, -50%);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .reviews-wrp { padding: 70px 0; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .reviews-wrp { padding: 70px 0; }
}

@media (max-width: 767px) {
    .reviews-wrp { padding: 48px 0; }
    .reviews-main .section-title {margin: 0 0 32px;}
    .reviews-detail-item .small-title {font-size: 22px;}
    .reviews-detail-item .detail p {font-size: 16px;}
    .reviews-logo-slider .slick-prev { left: 33px; transform: translate(0, -50%); -webkit-transform: translate(0, -50%); -moz-transform: translate(0,   -50%); -ms-transform: translate(0, -50%); -o-transform: translate(0, -50%); }
    .reviews-logo-slider .slick-next { justify-content: flex-end; right: 33px; transform:translate(0, -50%); -webkit-transform:translate(0, -50%); -moz-transform:translate(0, -50%); -ms-transform:translate(0, -50%); -o-transform:translate(0, -50%); }
}