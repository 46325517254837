@font-face {
    font-family: 'SegoeUI';
    src: url('SegoeUI.eot');
    src: url('SegoeUI.eot') format('embedded-opentype'),
         url('SegoeUI.woff2') format('woff2'),
         url('SegoeUI.woff') format('woff'),
         url('SegoeUI.ttf') format('truetype'),
         url('SegoeUI.svg#SegoeUI') format('svg');
}

@font-face {
    font-family: 'segoeuisemibold';
    src: url('segoeuisemibold.eot');
    src: url('segoeuisemibold.eot') format('embedded-opentype'),
         url('segoeuisemibold.woff2') format('woff2'),
         url('segoeuisemibold.woff') format('woff'),
         url('segoeuisemibold.ttf') format('truetype'),
         url('segoeuisemibold.svg#segoeuisemibold') format('svg');
}


@font-face {
    font-family: 'SegoeUIBold';
    src: url('SegoeUIBold.eot');
    src: url('SegoeUIBold.eot') format('embedded-opentype'),
         url('SegoeUIBold.woff2') format('woff2'),
         url('SegoeUIBold.woff') format('woff'),
         url('SegoeUIBold.ttf') format('truetype'),
         url('SegoeUIBold.svg#SegoeUIBold') format('svg');
}

@font-face {
    font-family: 'LeagueSpartanBold';
    src: url('LeagueSpartanBold.eot');
    src: url('LeagueSpartanBold.eot') format('embedded-opentype'),
         url('LeagueSpartanBold.woff2') format('woff2'),
         url('LeagueSpartanBold.woff') format('woff'),
         url('LeagueSpartanBold.ttf') format('truetype'),
         url('LeagueSpartanBold.svg#LeagueSpartanBold') format('svg');
}

@font-face {
    font-family: 'LeagueSpartanBlack';
    src: url('LeagueSpartanBlack.eot');
    src: url('LeagueSpartanBlack.eot') format('embedded-opentype'),
         url('LeagueSpartanBlack.woff2') format('woff2'),
         url('LeagueSpartanBlack.woff') format('woff'),
         url('LeagueSpartanBlack.ttf') format('truetype'),
         url('LeagueSpartanBlack.svg#LeagueSpartanBlack') format('svg');
}
