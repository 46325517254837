.contact-us {
    padding: 96px 0;
    width: 100%;
    height: 100%;
}

.contact-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 450px;
    height: 100%;
}

.contact-info .section-title {
    color: #FFF;
}

.contact-info .common-detail {
    margin-top: 16px;
    color: #EFEFEF;
    font-size: 18px;
    line-height: 150%;
}

.contact-detail .contact-list {
    margin-top: 169px;
}

.contact-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.contact-list ul li {
    position: relative;
    list-style: none;
    margin-bottom: 16px;
    color: #F7F7F7;
    font-size: 18px;
    line-height: 150%;
    padding-left: 35px;
}

.contact-list ul li::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    background: #F7F7F7;
    left: 0;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.contact-list ul li:last-child {
    margin-bottom: 0;
}

.contact-list ul li::after {
    position: absolute;
    content: "";
    background-image: url('../../images/checkmark.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 13px;
    height: 10px;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.contact-form-part {
    width: 100%;
    max-width: 481px;
    height: 100%;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 24px;
}

.contact-form-block {
    margin-top: 24px;
}

.contact-form-block .form .form-group {
    position: relative;
}

.contact-form-block .form .form-block {
    margin-bottom: 16px;
}

.contact-form-block .form .form-block .error {
    font-size: 14px;
    color: #ff0000;
}

.contact-form-block .success {
    font-size: 14px;
    color: #008800;
}

.contact-form-block .form .form-group .form-control {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #F7F7F7;
    padding: 22px 16px 7px;
    resize: none;
}

.contact-form-block .form .form-group label {
    position: absolute;
    color: #484848;
    pointer-events: none;
    transition: 0.4s;
    left: 16px;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
}

.contact-form-block .form .form-group.textarea label{
    top: 20px;
}

.contact-form-block .form .form-group .form-control:focus {
    box-shadow: none;
    border: 1px solid #062276;
}

.contact-form-block .form .form-group .form-control:focus+label{
    font-size: 12px;
    color: #062276;
    line-height: 130%;
}

.contact-form-block .row{
    margin: 0 -8px;
}

.contact-form-block .row > *{
    padding: 0 8px;
}

.contact-form-block .form .form-group textarea.form-control{
    padding: 22px 16px 24px;
}

.contact-form-block .form .submit-btn{
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.contact-form-block .form .submit-btn .btn{
    padding: 16px 28px;
}

::-webkit-inner-spin-button{
    display: none;
}

.contact-form-block .form-group .form-control:focus,
.contact-form-block .form-group .form-control:not(:placeholder-shown) {
    border-color: #062276;
}

.contact-form-block .form-group .form-control:focus + label,
.contact-form-block .form-group .form-control:not(:placeholder-shown) + label {
    top: 15px;
    left: 12px;
    font-size: 12px;
    color: #062276;
}


/* responsive start */
@media (min-width: 768px) and (max-width: 991px){
    .contact-us{padding: 64px 0;}
    .contact-detail{max-width: 100%;}
    .contact-info .common-detail{font-size: 16px;}
    .contact-detail .contact-list{margin-top: 32px;}
    .contact-form-part{margin: 32px auto 0; height: calc(100% - 32px);}
}

@media (max-width: 767px){
    .contact-us{padding: 64px 0;}
    .contact-detail{max-width: 100%;}
    .contact-info .common-detail{font-size: 16px;}
    .contact-detail .contact-list{margin-top: 32px;}
    .contact-form-part{margin: 32px auto 0; height: calc(100% - 32px); max-width: 100%;}
    .contact-form-block .form .form-group .form-control::placeholder{font-size: 14px;}
    .contact-form-block .form .submit-btn{display: block;}
    .contact-form-block .form .submit-btn .btn{ width: 100%; display: flex; justify-content: center; align-items: center;}
}
/* responsive end */