@charset "utf-8";

* {
  margin: 0;
  padding: 0;
}

body { 
	font-family: 'SegoeUI';
	font-size: 16px;
	line-height: 160%;
  font-style: normal;
	background-color: #fff;
	color: #727272;
}

a,
input,
button,
textarea {
	outline: none !important;
}

a {
	text-decoration: none;
	transition: all ease-in-out 300ms;
	-webkit-transition: all ease-in-out 300ms;
	-ms-transition: all ease-in-out 300ms;
	-o-transition: all ease-in-out 300ms;
	-moz-transition: all ease-in-out 300ms;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
}

p{
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}


.container-fluid,
.container {
	padding-left: 15px;
	padding-right: 15px;
}

.row {
	margin-left: -15px;
	margin-right: -15px;
}

.row>* {
	padding-left: 15px;
	padding-right: 15px;
}



/* Common Style Start */
.banner-title {
  font-family: 'LeagueSpartanBlack';
  font-size: 72px;
  line-height: 100%;
  font-weight: normal;
  color: #FFFFFF;
  text-transform: uppercase;
}

.banner-title * {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}

.section-title {
  font-family: 'LeagueSpartanBold';
  font-size: 48px;
  line-height: 100%;
  font-weight: normal;
  color: #121212;
}

.section-title * {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}

.block-title {
  font-family: 'SegoeUIBold';
  font-size: 32px;
  line-height: 100%;
  font-weight: normal;
  color: #FFFFFF;
}

.block-title * {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}

.small-title {
  font-family: 'LeagueSpartanBold';
  font-size: 24px;
  line-height: 130%;
  font-weight: normal;
  color: #323232;
}

.small-title * {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}

.common-detail {
  font-size: 16px;
  line-height: 160%;
  font-weight: normal;
}

.common-detail * {
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}

.btn {  
  font-family: 'SegoeUIBold';
  font-size: 18px;
  line-height: 130%;
  border: none;
  padding: 16px 24px;
  text-transform: uppercase;
} 

.btn-gradient {
    color: #fff;
    background: -moz-linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
    background: -webkit-linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
    background: linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.btn-gradient:hover,
.btn-gradient:focus,
.btn-gradient:active,
.btn-gradient.active {
  color: #fff !important;
  background: -moz-linear-gradient(180deg, #E02C22 0%, #E02C22 100%) !important;
  background: -webkit-linear-gradient(180deg, #E02C22 0%, #E02C22 100%) !important;
  background: linear-gradient(180deg, #E02C22 0%, #E02C22 100%) !important;
}

.btn-text {
  background: -moz-linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
  background: -webkit-linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
  background: linear-gradient(180deg, #ED771B 0%, #E02C22 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0;
}

.btn-text:hover {
  background: -moz-linear-gradient(180deg, #E02C22 0%, #E02C22 100%);
  background: -webkit-linear-gradient(180deg, #E02C22 0%, #E02C22 100%);
  background: linear-gradient(180deg, #E02C22 0%, #E02C22 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* Common Style End */

/* Responsive Start */
@media (min-width: 1400px) {
  .container {max-width: 1230px;}
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .container {max-width: 1140px;}
  
  .banner-title {font-size: 60px;}   
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .container {max-width: 1100px;}
  
  .banner-title {font-size: 58px;}   
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner-title {font-size: 52px;}   
  .section-title {font-size: 42px;}
}

@media (min-width: 768px) and (max-width: 991px) {
  .banner-title {font-size: 48px;}  
  .section-title {font-size: 40px;} 
}

@media (max-width: 767px) {
  .container {padding: 0 24px;}

  .banner-title {font-family: 'LeagueSpartanBold'; font-size: 48px; text-transform: unset;}   
  .btn{font-size: 16px; }
  .section-title {font-size: 40px;}
  .small-title {font-size: 18px;}
  .block-title {font-size: 22px;}
}
/* Responsive End */